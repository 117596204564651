import { Col, Form, Row } from "react-bootstrap";
import styles from "./Members.module.css";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-datepicker/dist/react-datepicker.css";
import ProfileDemo from "../../Assests/Images/person.png";
import LogBook from "./LogBook";
import CustomTabButton, {
  CustomButton,
} from "../../ReactBootstrap/CustomTabButton";
import CalenderIcon from "../../Assests/Images/Calender.svg";
import SublyApi from "../../Helpers/Api";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import SelectImg from "../../Assests/Images/SelectImg.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import { fetchBrandList, userLogoutClear } from "../../store/slices/userSlice";
import {
  cleanData,
  removeExtraPlus,
  transformTimeArray,
} from "../../Utils/RemovePlus/RemovePlus";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import CropImg from "../../Common/Cropper/CropImg";
import { CONSTANT } from "../../Utils/Constant";
import Loader from "../../Utils/Loader/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { DAYS_OF_WEEK, MONTH_CODE, MONTH_OF_YEAR } from "./MockData";
import Calender from "../../Assests/Images/Calender.svg";
import CalenderComp from "../../Common/DatePicker/CalenderComp";
import {
  fetchImageAsFile,
  formatDate,
  isValidDate,
} from "../../Utils/CommonFunction";
import CalenderForMembership from "../../Common/DatePicker/CalenderForMembership";
import { MASKING } from "../../Common/PhoneMasking";
import RemoveBrandModal from "./RemoveModal";
import MembershipModal from "./MembershipModal";
import MemberCalenderComp from "../../Common/DatePicker/MemberCalenderComp";

function AddMember({ setShowtab }) {
  const fileInputRef = useRef(null);
  const { userToken, brandListData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("");
  const [detailTabShow, setDetailTabShow] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [phone, setPhone] = useState("");
  const [phoneDialCode, setPhoneDialCode] = useState("27");
  const [phoneCountryCode, setPhoneCountryCode] = useState("Za");
  const [isCropper, setIsCropper] = useState(false);
  const [isProfileRemove, setIsProfileRemove] = useState(1);
  const [heightList, setHeightList] = useState([]);
  const [weightList, setWeightList] = useState([]);
  const [distanceList, setDistanceList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [brandMemberActive, setBrandMmeberActive] = useState(0);
  const [brandCanViewOther, setBrandCanViewOther] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [noCropImg, setNoCropImg] = useState(null);
  const [isEmailShare, setIsEmailShare] = useState(0);
  const [isPhoneShare, setIsPhoneShare] = useState(0);
  const [inputValues, setInputValues] = useState(
    distanceList?.reduce((acc, item) => {
      acc[item.id] = {
        hours: 0,
        minutes: 0,
        seconds: 0,
        createdAt: 0,
      };
      return acc;
    }, {})
  );
  const [memberBrandName, setMemberBrandName] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [removeBrandModal, setRemoveBrandModal] = useState(false);
  const [brandToRemove, setBrandToRemove] = useState(null);
  const handleCloseRemove = () => setRemoveBrandModal(false);

  const newBrandRef = useRef(null);

  useEffect(() => {
    if (newBrandRef.current) {
      newBrandRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedBrand]);

  const brandRefs = useRef({});

  useEffect(() => {
    if (selectedBrand?.length > 0) {
      const lastBrandId = selectedBrand[selectedBrand.length - 1].id;
      const lastBrandElement = brandRefs.current[lastBrandId];

      if (lastBrandElement) {
        lastBrandElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [selectedBrand]);

  const [showCrop, setShowCrop] = useState(false);
  const [modifyTimeArray, setModifyTimeArray] = useState([]);
  const [activeMember, setActiveMember] = useState(false);
  const [defaultContryCountry, setDefaulCountry] = useState("vbmOpe");
  const [selectCountry, setSelectCountry] = useState("");
  const [defaultState, setDefaulState] = useState("");
  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [showDate, setShowDate] = useState(false);
  const currentDate = new Date();

  const handleCloseCrop = () => {
    setShowCrop(false);
    fileInputRef.current.value = "";
    setIsCropper(false);
  };

  // useEffect(() => {
  //   var daySelected = day;

  //   if (day?.toString()?.length < 2) {
  //     daySelected = `${0}${day}`;
  //   } else {
  //     daySelected = day;
  //   }
  //   const rightFormatOfDate = `${year}-${MONTH_CODE[month]}-${daySelected}`;
  //   console.log("rightFormatOfDate",rightFormatOfDate)
  //   if (rightFormatOfDate) {
  //     setSelectedDate(rightFormatOfDate);
  //   }
  // }, [day, year, month, weekday]);

  useEffect(() => {
    let daySelected = day;

    if (day?.toString()?.length < 2) {
      daySelected = `0${day}`;
    }

    // Ensure year and month are defined before constructing the date
    if (year && month && daySelected) {
      const rightFormatOfDate = `${year}-${MONTH_CODE[month]}-${daySelected}`;
      setSelectedDate(rightFormatOfDate);
    } else {
      // Set an empty string if the date is not properly selected
      setSelectedDate("");
    }
  }, [day, year, month, weekday]);

  const handleShowDate = () => setShowDate(true);

  const handleCloseDate = () => setShowDate(false);

  var tileDisabledForStartDate = ({ date, view }) => {
    const minDate = new Date().setHours(0, 0, 0, 0);

    return date > minDate;
  };

  // membership calnder
  // var tileDisabledForStartDateMember = ({ date, view }) => {
  //   // Get the current date
  //   const today = new Date();
  //   // Set minimum selectable date to exactly one year from today
  //   const minSelectableDate = new Date(today);
  //   minSelectableDate.setFullYear(today.getFullYear() + 1);
  //   minSelectableDate.setHours(0, 0, 0, 0);
  //   // Disable dates before the minimum selectable date
  //   return view === "month" && date < new Date().setHours(0, 0, 0, 0);
  //   return date < minSelectableDate;
  // };

  // var tileDisabledForStartDateMembership = ({ date, view }) => {
  //   const today = new Date();
  //   const minSelectableDate = new Date(today);
  //   minSelectableDate.setFullYear(today.getFullYear() + 1);
  //   minSelectableDate.setHours(0, 0, 0, 0);

  //   return view === "month" && date < minSelectableDate;
  // };

  // This function will disable all dates before January 1st of next year
  const tileDisabledForStartDateMembership = ({ date, view }) => {
    const today = new Date();

    // Disable dates before today (including today), but allow all future dates
    if (view === "month") {
      const isBeforeToday = date < today;
      return isBeforeToday; // Disable past dates
    }

    return false; // Enable future dates
  };

  // =====================DATE PICKER ========================

  const [weekdayMembership, setWeekdayMembership] = useState("");
  const [dayMembership, setDayMembership] = useState("");
  const [monthMembership, setMonthMembership] = useState("");
  const [yearMembership, setYearMembership] = useState();
  const [selectedDateMembership, setSelectedDateMembership] = useState("");
  const [dateToPreview, setDateToPreview] = useState();
  const [showDateMembership, setShowDateMembership] = useState(false);

  const [membershipModal, setMembershipModal] = useState(false);

  const handleCloseMembership = () => setMembershipModal(false);

  useEffect(() => {
    if (!showDateMembership) {
      setShowDateMembership("");
    }
  }, [showDateMembership]);

  const [dates, setDates] = useState([]);
  const [dateId, setDateId] = useState("");

  const handleDateSelection = (brandId, selectedDate) => {
    setDates((prevDates) => {
      const existingDateIndex = prevDates?.findIndex(
        (dateObj) => dateObj?.idOfDate === brandId
      );

      if (existingDateIndex !== -1) {
        const updatedDates = [...prevDates];
        updatedDates[existingDateIndex] = {
          date: selectedDate,
          idOfDate: brandId,
        };
        return updatedDates;
      } else {
        return [...prevDates, { date: selectedDate, idOfDate: brandId }];
      }
    });
  };

  useEffect(() => {
    if (isValidDate(selectedDateMembership) && dateId && !showDateMembership) {
      handleDateSelection(dateId, selectedDateMembership);
    }
  }, [selectedDateMembership, showDateMembership]);

  const currentDateMembership = new Date();

  useEffect(() => {
    setWeekdayMembership(DAYS_OF_WEEK[currentDateMembership.getDay()]);
    setDay(currentDateMembership.getDate());
    setMonthMembership(MONTH_OF_YEAR[currentDateMembership.getMonth()]);
    setYearMembership(currentDateMembership.getFullYear());
  }, []);

  useEffect(() => {
    var daySelected = day;

    if (dayMembership?.toString()?.length < 2) {
      daySelected = `${0}${dayMembership}`;
    } else {
      daySelected = dayMembership;
    }
    const rightFormatOfDate = `${yearMembership}-${MONTH_CODE[monthMembership]}-${daySelected}`;
    if (rightFormatOfDate) {
      setSelectedDateMembership(rightFormatOfDate);
    }
  }, [dayMembership, yearMembership, monthMembership, weekdayMembership]);

  const handleShowDateMembership = () => setShowDateMembership(true);

  const handleCloseDateMembership = () => setShowDateMembership(false);

  //===========function for form validation using useform===========
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    mode: "onChange",
  });

  //======= function for handle active inactive status=====
  const activeStatus = () => {
    setActiveMember(!activeMember);
  };

  //======== Show the modal and store the brand ID to remove=======
  const handleShowRemove = (id) => {
    setBrandToRemove(id);
    setRemoveBrandModal(true);
  };

  //===== Remove the brand when confirmed=====
  // const handleRemoveClick = () => {
  //   if (brandToRemove) {
  //     setSelectedBrand(
  //       selectedBrand.filter((brand) => brand.id !== brandToRemove)
  //     );

  //     const updatedData = dates.filter(
  //       (item) => item.idOfDate !== brandToRemove
  //     );
  //     setDates(updatedData);

  //     setBrandToRemove(null); // Reset after removal
  //     setRemoveBrandModal(false); // Close the modal
  //   }
  // };

  const handleRemoveClick = (brandId) => {
    // Remove the brand from selectedBrand state
    setSelectedBrand((prevBrands) =>
      prevBrands.filter((brand) => brand.id !== brandId)
    );

    // Remove the date associated with the brand
    setDates((prevDates) =>
      prevDates.filter((dateObj) => dateObj.idOfDate !== brandId)
    );
  };

  //========== function for calling get brand list api==========
  async function getBrandList(
    pageNum = 1,
    brandSearch = "",
    onLoadMore = false,
    listForAddMember = 1
  ) {
    await dispatch(
      fetchBrandList({
        userToken: userToken,
        page_no: pageNum,
        search: brandSearch,
        onLoadMore: onLoadMore,
        listForAddMember: listForAddMember,
      })
    ).then((responsejson) => {
      const { response } = responsejson.payload;
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      }
    });
  }

  useEffect(() => {
    getBrandList();
  }, []);

  // -----------function for handle profile image-----------
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNoCropImg(file);
    setIsProfileRemove(0);
    setIsCropper(true);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (isCropper) {
      setShowCrop(true);
    }
  }, [isCropper]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  //========= function for calling height weight meta list api========
  const getHeightWidthDistanceMeta = () => {
    const HeightResponse = SublyApi.HeightWidthDistanceMeta("heightMeta");
    HeightResponse.then((res) => {
      setHeightList(res?.data?.heightMeta);
      setIsLoading(false);
    });

    const WeightResponse = SublyApi.HeightWidthDistanceMeta("weightMeta");
    WeightResponse.then((res) => {
      setWeightList(res?.data?.weightMeta);
      setIsLoading(false);
    });

    const DistanceResponse = SublyApi.HeightWidthDistanceMeta("distanceMeta");
    DistanceResponse.then((res) => {
      setDistanceList(res?.data?.distanceMeta);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getHeightWidthDistanceMeta();
    getCountryMetaData();
  }, []);

  //====== function for calling meta list api========
  const getCountryMetaData = () => {
    setIsLoading(true);
    const HeightResponse = SublyApi.countryMetaData();
    HeightResponse.then((res) => {
      setCountryList(res?.data?.countryMeta);
      setIsLoading(false);
    });
  };

  const getStateMetaData = (countryId) => {
    setIsLoading(true);
    const StateResponse = SublyApi.stateMetaData(userToken, countryId);
    StateResponse.then((res) => {
      setStateList(res?.data?.stateMeta);
      setIsLoading(false);
    });
  };

  //=========== function for show default country state ============
  useEffect(() => {
    getStateMetaData(defaultContryCountry);
  }, [defaultContryCountry]);

  //=========== function for show default country  ============
  useEffect(() => {
    setValue("COUNTRY", defaultContryCountry);
  }, [countryList]);

  useEffect(() => {
    if (selectCountry != "" && selectCountry != defaultContryCountry) {
      setValue("STATE", "");
    } else {
      setValue("STATE", defaultState);
    }
  }, [stateList, defaultState]);

  //=========== function for calling getCountry data api  ============
  useEffect(() => {
    getCountryMetaData();
  }, []);

  //======= function for handle personal best time========

  const handleInputChange = (id, field, value) => {
    const inputField = Number(value);
    if (field !== "hours") {
      if (inputField < 60) {
        let stringValue = value.toString();

        let validatedValue = Number(stringValue);

        if (
          (field === "minutes" || field === "seconds") &&
          (validatedValue < 0 || validatedValue > 59)
        ) {
          validatedValue = Math.max(0, Math.min(value, validatedValue));
        }

        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: {
            ...prevValues[id],
            [field]: validatedValue,
            createdAt: new Date().toISOString(),
          },
        }));
      }
    } else if (field == "hours") {
      if (value < 100) {
        let stringValue = value.toString();

        let validatedValue = Number(stringValue);

        if (field === "hours" && (validatedValue < 0 || validatedValue > 99)) {
          validatedValue = Math.max(0, Math.min(value, validatedValue));
        }

        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: {
            ...prevValues[id],
            [field]: validatedValue,
            createdAt: new Date().toISOString(),
          },
        }));
      }
    }
  };

  const addLeadingZero = (digit) => {
    const digitStr = String(digit);

    if (digitStr?.length === 1) {
      return `0${digitStr}`;
    } else {
      return digitStr;
    }
  };

  const generateObjectArray = () => {
    return distanceList.map((item) => ({
      id: item.id,
      ...inputValues[item.id],
    }));
  };

  const modifyArray = () => {
    const objectsArray = generateObjectArray();
    const updatedArray = objectsArray
      .filter((obj) => "hours" in obj || "minutes" in obj || "seconds" in obj)
      .map((obj) => ({
        ...obj,
        hours: obj.hours || "0",
        minutes: obj.minutes || "0",
        seconds: obj.seconds || "0",
      }));

    setModifyTimeArray(updatedArray);
  };

  useEffect(() => {
    modifyArray();
  }, [inputValues]);

  const fetchImageAsBinary = async (url) => {
    setIsLoading(true);
    try {
      const imageFile = await fetchImageAsFile(`${url}`, "myImage.jpg");
      setIsLoading(false);

      return imageFile;
    } catch (error) {
      console.error("Error fetching image:", error);
      setIsLoading(false);

      throw error;
    }
  };

  const getDateById = (id) => {
    const foundItem = dates?.find((item) => item?.idOfDate === id);
    if (foundItem?.date) {
      // Convert the date to a string in the format YYYY-MM-DD
      const dateOnly = new Date(foundItem.date).toISOString().split("T")[0];
      return dateOnly;
    }
    return null;
  };

  const dobRef = useRef(null);

  //======= function for submit add member form=======
  // const OnSubmit = async (data) => {
  //   setIsLoading(true);
  //   if (selectedBrand?.length > 0 && selectedBrand?.length !== dates?.length) {
  //     Toster(`Please select "Membership Valid Until" date`, "error");
  //     setIsLoading(false);
  //   }
  //   if (!phone || phone.length <= 4) {
  //     setIsLoading(false);
  //     setError("CONTACT_NO", {
  //       type: "manual",
  //       message: "Phone number is required ",
  //     });

  //     numberRef.current?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //     });
  //     return;
  //   }

  //   const DOB =
  //     selectedDate &&
  //     selectedDate !== null &&
  //     selectedDate !== "undefined-undefined-undefined";
  //   if (
  //     !selectedDate ||
  //     selectedDate == null ||
  //     selectedDate == "undefined-undefined-undefined"
  //   ) {
  //     dobRef.current?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //     });
  //     Toster("Please select DOB", "error");
  //     setIsLoading(false);
  //   }
  //   const PostData = new FormData();
  //   PostData.append("firstName", data.FIRST_NAME);
  //   PostData.append("lastName", data.LAST_NAME);
  //   PostData.append("email", data.ADD_EMAIL);
  //   PostData.append("isEmailPubliclyShared", isEmailShare ? 1 : 0);
  //   PostData.append("phoneDialCode", phoneDialCode);
  //   PostData.append("phoneCountryCode", phoneCountryCode);
  //   PostData.append("phoneNumber", phone);
  //   PostData.append("isNumberPubliclyShared", isPhoneShare ? 1 : 0);
  //   PostData.append("dateOfBirth", selectedDate);
  //   PostData.append("gender", data.GENDER);
  //   PostData.append("height", data.HEIGHT ? data.HEIGHT : "");
  //   PostData.append("weight", data.WEIGHT ? data.WEIGHT : "");
  //   PostData.append("countryMeta", data.COUNTRY);
  //   PostData.append("stateMeta", data.STATE);
  //   PostData.append("town", data.TOWN_CITY);
  //   const imageFile = selectedImage
  //     ? await fetchImageAsBinary(selectedImage)
  //     : "";
  //   PostData.append("profilePicture", imageFile);
  //   var PbArray = transformTimeArray(modifyTimeArray);
  //   PbArray = PbArray.filter((item) => item.time !== "00:00:00");
  //   PostData.append("personalBests", JSON.stringify(PbArray));
  //   //===== Create an array to hold the brand objects=======
  //   const brandsArray = selectedBrand.map((brand) => ({
  //     id: brand.id,
  //     membershipValidUntil: getDateById(brand.id),
  //     activeMembership: brandMemberActive[brand.id] ? "1" : "0",
  //     canViewOtherMember: brandCanViewOther[brand.id] ? "1" : "0",
  //     createdAt: "",
  //   }));

  //   PostData.append("brands", JSON.stringify(brandsArray));
  //   PostData.append(
  //     "removeProfilePicture",
  //     !selectedImage && !noCropImg ? 1 : 0
  //   );
  //   PostData.append("status", activeMember ? "1" : "0");
  //   if (
  //     (!phone || phone?.length > 4) &&
  //     (selectedBrand?.length == dates?.length || selectedBrand?.length < 1)
  //   ) {
  //     setIsLoading(true);
  //     SublyApi.AddMember(userToken, PostData).then((response) => {
  //       setIsLoading(false);
  //       const ResponseCode = STATUS_MSG[response?.data?.code];
  //       if (response?.code === STATUS_CODES.SUCCESS) {
  //         setIsLoading(false);
  //         Toster(t("MEMBER_ADD_SUCCESSFULLY"), "success");
  //         reset();
  //         setPhone("");
  //         setPhoneDialCode("27");
  //         setPhoneCountryCode("za");
  //         setIsEmailShare(0);
  //         setIsPhoneShare(0);
  //         setIsProfileRemove(1);
  //         setIsCropper(false);
  //         setSelectedImage(null);
  //         setImageSrc(null);
  //         setNoCropImg(null);
  //         setInputValues(
  //           distanceList.reduce((acc, item) => {
  //             acc[item.id] = {
  //               hours: 0,
  //               minutes: 0,
  //               seconds: 0,
  //               createdAt: 0,
  //             };
  //             return acc;
  //           }, {})
  //         );
  //         setModifyTimeArray([]);

  //         setShowtab(1);
  //       } else if (response?.data?.code == STATUS_CODES.VALIDATION_ERROR) {
  //         Toster(response?.data?.message, "error");
  //       } else if (response?.data?.code == STATUS_CODES.MEMBER_LIMIT_EXCEEDED) {
  //         setMemberBrandName(response.data.data);
  //         setMembershipModal(true);
  //       } else {
  //         Toster(t(ResponseCode), "error");
  //       }
  //     });
  //     // setIsLoading(false);
  //   }
  // };

  const OnSubmit = async (data) => {
    setIsLoading(true);

    // Check if selectedBrand has dates for each selected brand
    if (selectedBrand?.length > 0 && selectedBrand?.length !== dates?.length) {
      Toster(`Please select "Membership Valid Until" date`, "error");
      setIsLoading(false);
      return; // Exit the function if this condition fails
    }

    // Check if the phone number is valid
    if (!phone || phone.length <= 4) {
      setIsLoading(false);
      setError("CONTACT_NO", {
        type: "manual",
        message: "Phone number is required ",
      });

      numberRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return; // Exit the function if phone validation fails
    }

    // Check if DOB is selected, if not, scroll to the DOB field
    const isDOBValid =
      (selectedDate && selectedDate !== "undefined-undefined-01") || "";
    if (!isDOBValid) {
      dobRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      Toster("Please select DOB", "error");
      setIsLoading(false);
      return;
    }

    // Form submission logic here after validation passes
    const PostData = new FormData();
    PostData.append("firstName", data.FIRST_NAME);
    PostData.append("lastName", data.LAST_NAME);
    PostData.append("email", data.ADD_EMAIL);
    PostData.append("isEmailPubliclyShared", isEmailShare ? 1 : 0);
    PostData.append("phoneDialCode", phoneDialCode);
    PostData.append("phoneCountryCode", phoneCountryCode);
    PostData.append("phoneNumber", phone);
    PostData.append("isNumberPubliclyShared", isPhoneShare ? 1 : 0);
    PostData.append("dateOfBirth", selectedDate);
    PostData.append("gender", data.GENDER);
    PostData.append("height", data.HEIGHT ? data.HEIGHT : "");
    PostData.append("weight", data.WEIGHT ? data.WEIGHT : "");
    PostData.append("countryMeta", data.COUNTRY);
    PostData.append("stateMeta", data.STATE);
    PostData.append("town", data.TOWN_CITY);

    const imageFile = selectedImage
      ? await fetchImageAsBinary(selectedImage)
      : "";
    PostData.append("profilePicture", imageFile);

    var PbArray = transformTimeArray(modifyTimeArray);
    PbArray = PbArray.filter((item) => item.time !== "00:00:00");
    PostData.append("personalBests", JSON.stringify(PbArray));

    // Create an array for selected brands
    const brandsArray = selectedBrand.map((brand) => ({
      id: brand.id,
      membershipValidUntil: getDateById(brand.id),
      activeMembership: brandMemberActive[brand.id] ? "1" : "0",
      canViewOtherMember: brandCanViewOther[brand.id] ? "1" : "0",
      createdAt: "",
    }));
    PostData.append("brands", JSON.stringify(brandsArray));

    PostData.append(
      "removeProfilePicture",
      !selectedImage && !noCropImg ? 1 : 0
    );
    PostData.append("status", activeMember ? "1" : "0");

    if (selectedBrand?.length == dates?.length || selectedBrand?.length < 1) {
      setIsLoading(true);
      // SublyApi.AddMember(userToken, PostData).then((response) => {
      //   setIsLoading(false);
      //   const ResponseCode = STATUS_MSG[response?.data?.code];
      //   if (response?.code === STATUS_CODES.SUCCESS) {
      //     Toster(t("MEMBER_ADD_SUCCESSFULLY"), "success");
      //     reset();
      //     // Reset additional fields here...
      //   } else if (response?.data?.code == STATUS_CODES.VALIDATION_ERROR) {
      //     Toster(response?.data?.message, "error");
      //   } else if (response?.data?.code == STATUS_CODES.MEMBER_LIMIT_EXCEEDED) {
      //     setMemberBrandName(response.data.data);
      //     setMembershipModal(true);
      //   } else {
      //     Toster(t(ResponseCode), "error");
      //   }
      // });
      SublyApi.AddMember(userToken, PostData).then((response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];
        if (response?.code === STATUS_CODES.SUCCESS) {
          setIsLoading(false);
          Toster(t("MEMBER_ADD_SUCCESSFULLY"), "success");
          reset();
          setPhone("");
          setPhoneDialCode("27");
          setPhoneCountryCode("za");
          setIsEmailShare(0);
          setIsPhoneShare(0);
          setIsProfileRemove(1);
          setIsCropper(false);
          setSelectedImage(null);
          setImageSrc(null);
          setNoCropImg(null);
          setInputValues(
            distanceList.reduce((acc, item) => {
              acc[item.id] = {
                hours: 0,
                minutes: 0,
                seconds: 0,
                createdAt: 0,
              };
              return acc;
            }, {})
          );
          setModifyTimeArray([]);

          setShowtab(1);
        } else if (response?.data?.code == STATUS_CODES.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else if (response?.data?.code == STATUS_CODES.MEMBER_LIMIT_EXCEEDED) {
          setMemberBrandName(response.data.data);
          setMembershipModal(true);
        } else {
          Toster(t(ResponseCode), "error");
        }
      });
    }
  };

  // =======function for handle brand select========
  // old function
  // const handleBrandChange = () => {
  //   if (selectedBrandId) {
  //     const today = new Date();
  //     const nextYear = new Date(today);
  //     nextYear.setFullYear(today.getFullYear() + 1);

  //     const newBrand = {
  //       id: selectedBrandId,
  //       clubName: brandListData.find((brand) => brand.id === selectedBrandId)
  //         ?.clubName,
  //     };

  //     // Add the new brand and its membership expiration date
  //     setSelectedBrand((prevBrands) => [...prevBrands, newBrand]);

  //     // Set default date for membership (current date + one year)
  //     setDates((prevDates) => [
  //       ...prevDates,
  //       { idOfDate: selectedBrandId, date: nextYear },
  //     ]);

  //     // Clear selected brand
  //     setSelectedBrandId("");
  //   }
  // };

  // new function where we add scroller
  const handleBrandChange = () => {
    if (selectedBrandId) {
      // Check if the brand is already selected
      const isBrandAlreadySelected = selectedBrand.some(
        (brand) => brand.id === selectedBrandId
      );

      if (isBrandAlreadySelected) {
        // Scroll to the already selected brand
        const existingBrandElement = brandRefs.current[selectedBrandId];
        if (existingBrandElement) {
          existingBrandElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        return;
      }

      const today = new Date();
      const nextYear = new Date(today);
      nextYear.setFullYear(today.getFullYear() + 1);

      const newBrand = {
        id: selectedBrandId,
        clubName: brandListData.find((brand) => brand.id === selectedBrandId)
          ?.clubName,
      };

      // Add the new brand and its membership expiration date
      setSelectedBrand((prevBrands) => [...prevBrands, newBrand]);

      // Set default date for membership (current date + one year)
      setDates((prevDates) => [
        ...prevDates,
        { idOfDate: selectedBrandId, date: nextYear },
      ]);

      // Clear selected brand
      setSelectedBrandId("");
    }
  };

  //=== function for prevent white space====
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  //====== prevent  white space in email input====
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, ""));
  };

  const numberRef = useRef(null);

  useEffect(() => {
    if (!phone) {
      numberRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [formState.errors.CONTACT_NO, phone]);

  return (
    <div className={styles.memberDetailWrapper}>
      {isLoading && <Loader />}
      <div className={styles.memberSection}>
        <div className={styles.topHead}>
          {detailTabShow === 1 ? (
            <>
              <div className={styles.borderBoxEdit}>
                <div className={styles.headingAddMember}>
                  <p>{t("ADD_NEW_MEMBER")}</p>
                </div>
              </div>

              <div className={styles.bottomContent}>
                <div className={styles.memberDataEdit}>
                  <p>
                    {t("MEMBER_BTN")} <span>{t("DETAILS_TEXT")}</span>
                  </p>
                </div>
              </div>

              <Form onSubmit={handleSubmit(OnSubmit)}>
                <div className={styles.editForm}>
                  <Form.Group className={styles.DetailInput}>
                    <Form.Label className={styles.clublabelText}>
                      {t("FIRST_NAME")}
                    </Form.Label>
                    <Form.Control
                      className={styles.FormInput}
                      type="text"
                      placeholder={`${t("FIRST_NAME_PLACHOLDER")}`}
                      {...register("FIRST_NAME", {
                        onChange: (e) => handleKeyPress(e),
                        ...ValidationSchema.FIRST_NAME,
                      })}
                      isInvalid={!!formState.errors.FIRST_NAME}
                      autoFocus={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.FIRST_NAME?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label className={styles.clublabelText}>
                      {t("SIR_NAME")}
                    </Form.Label>
                    <Form.Control
                      className={styles.FormInput}
                      type="text"
                      placeholder={`${t("SIR_NAME_PLACEHOLDER")}`}
                      {...register("LAST_NAME", {
                        onChange: (e) => handleKeyPress(e),
                        ...ValidationSchema.LAST_NAME,
                      })}
                      isInvalid={!!formState.errors.LAST_NAME}
                      autoFocus={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.LAST_NAME?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label className={styles.clublabelText}>
                      {t("EMAIL_LABEL")}
                    </Form.Label>
                    <Form.Control
                      className={styles.FormInput}
                      type="text"
                      placeholder={t("EMAIL")}
                      {...register("ADD_EMAIL", {
                        onChange: (e) => onInputChange(e),
                        ...ValidationSchema.ADD_EMAIL,
                      })}
                      isInvalid={!!formState.errors.ADD_EMAIL}
                      autoFocus={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.ADD_EMAIL?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className={styles.shareCheck}>
                    <div className="memberCheck">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={isEmailShare}
                          onChange={() => {
                            setIsEmailShare(!isEmailShare);
                          }}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {t("SHARE_PUBLICLY")}
                        </label>
                      </div>
                    </div>
                  </div>

                  <Form.Group className={styles.inputIcon} ref={numberRef}>
                    <Form.Label className={styles.labelText}>
                      {t("CONTACT_NUMBER_LABEL")}
                    </Form.Label>
                    <div className="memberPhoneInputSet">
                      <div className="phoneCountry">
                        <div className="customPhoneDropDown">
                          <p>{phoneCountryCode?.toUpperCase()}</p>
                          <span>{removeExtraPlus(phoneDialCode)}</span>{" "}
                        </div>
                      </div>
                      <PhoneInput
                        defaultMask={MASKING[phoneDialCode]}
                        value={`${phoneDialCode} ${phone}`}
                        onChange={(value, country) => {
                          // If the country code has changed, reset the phone number
                          if (phoneCountryCode !== country.countryCode) {
                            setPhone("");
                          } else {
                            let dialCode = country.dialCode;
                            let phone = value.slice(
                              dialCode.length,
                              value.length
                            );
                            setPhone(phone);

                            // Clear the error if a valid phone number is entered
                            if (phone.length > 4) {
                              clearErrors("CONTACT_NO");
                            }
                          }

                          setPhoneCountryCode(country.countryCode);
                          setPhoneDialCode(country.dialCode);
                        }}
                        countryCodeEditable={false}
                        copyNumbersOnly={true}
                      />

                      <img
                        src={SelectImg}
                        alt="SelectImg"
                        className={styles.phoneSelectImg}
                      ></img>
                    </div>
                    <div className="validateError">
                      {!phone && t(formState.errors.CONTACT_NO?.message)}
                      {phone?.length > 0 &&
                        phone?.length <= 4 &&
                        t("CONTACT_NO_MUST_BE_ATLEAST_5_DIGIT")}
                    </div>
                  </Form.Group>

                  <div className={styles.shareCheck}>
                    <div className="memberCheck">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          checked={isPhoneShare}
                          id="flexCheckDefault"
                          onChange={() => {
                            setIsPhoneShare(!isPhoneShare);
                          }}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {t("SHARE_PUBLICLY")}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className={styles.datePickerBox} ref={dobRef}>
                    <p>{t("DOB_REQUIRED")}</p>
                    <div
                      className={styles.dateSelection}
                      onClick={handleShowDate}
                    >
                      <img src={Calender} alt="calender"></img>
                      {showDate ? (
                        <p>{t("Select DOB")}</p>
                      ) : (
                        <p>
                          {day && month && year
                            ? `${day} ${month} ${year}`
                            : "Select DOB"}
                        </p>
                      )}
                    </div>
                  </div>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label>{t("GENDER_REQUIRED")}</Form.Label>
                    <div className={styles.checkBoxArea}>
                      <Form.Check
                        inline
                        label={t("FEMALE")}
                        name="group1"
                        type={"radio"}
                        id={`inline-${"radio"}-2`}
                        value={2}
                        {...register("GENDER", ValidationSchema.GENDER)}
                        isInvalid={!!formState.errors.GENDER}
                      />
                      <Form.Check
                        inline
                        label={t("MALE")}
                        name="group1"
                        type={"radio"}
                        id={`inline-${"radio"}-1`}
                        value={1}
                        {...register("GENDER", ValidationSchema.GENDER)}
                        isInvalid={!!formState.errors.GENDER}
                      />
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.GENDER?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className={styles.weightSelect}>
                    <Form.Group className={styles.DetailInput}>
                      <Form.Label className={styles.labelText}>
                        {t("HEIGHT_LABEL")}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("HEIGHT")}
                        onChange={(e) => {
                          setValue("HEIGHT", e.target.value);
                        }}
                      >
                        <option value="" defaultValue>
                          {t("SELECT_HEIGHT")}
                        </option>
                        {heightList?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.height}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <img
                        src={SelectImg}
                        alt="SelectImg"
                        className={styles.selectImg}
                      ></img>
                    </Form.Group>
                  </div>

                  <div className={styles.weightSelect}>
                    <Form.Group className={styles.DetailInput}>
                      <Form.Label className={styles.labelText}>
                        {t("WEIGHT_LABEL")}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("WEIGHT")}
                        onChange={(e) => {
                          setValue("WEIGHT", e.target.value);
                        }}
                      >
                        <option value="" defaultValue>
                          {t("SELECT_WEIGHT")}
                        </option>
                        {weightList?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.weight}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <img
                        src={SelectImg}
                        alt="SelectImg"
                        className={styles.selectImg}
                      ></img>
                    </Form.Group>
                  </div>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label className={styles.CountrylabelText}>
                      {t("COUNTRY_REQUIRED")}
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      {...register("COUNTRY", ValidationSchema.COUNTRY)}
                      isInvalid={!!formState.errors.COUNTRY}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setValue("COUNTRY", selectedValue);
                        setValue("STATE", "");
                        // Clear the error when a valid option is selected
                        if (selectedValue) {
                          clearErrors("COUNTRY");
                        }

                        getStateMetaData(selectedValue);
                      }}
                      defaultValue=""
                    >
                      <option value="" defaultValue>
                        {t("SELECT_COUNTRY_PLACEHOLDER")}
                      </option>
                      {countryList?.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <img
                      src={SelectImg}
                      alt="SelectImg"
                      className={styles.selectImgCountry}
                    />
                  </Form.Group>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label className={styles.labelText}>
                      {t("STATE_PROVINCE_REQUIRED")}
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      {...register("STATE", ValidationSchema.STATE)}
                      isInvalid={!!formState.errors.STATE}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setValue("STATE", selectedValue);

                        // Clear the error when a valid option is selected
                        if (selectedValue) {
                          clearErrors("STATE");
                        }
                      }}
                      disabled={stateList?.length === 0}
                    >
                      <option value="" defaultValue>
                        {t("STATE_PLACEHOLDER_TEXT")}
                      </option>
                      {stateList?.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <img
                      src={SelectImg}
                      alt="SelectImg"
                      className={styles.selectImgCountry}
                    />
                  </Form.Group>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label>{t("TOWN_CITY_REQUIRED")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("TOWN_PLACEHOLDER_TEXT")}
                      {...register("TOWN_CITY", {
                        onChange: (e) => handleKeyPress(e),
                        ...ValidationSchema.TOWN_CITY,
                      })}
                      isInvalid={!!formState.errors.TOWN_CITY}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.TOWN_CITY?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className={styles.devider}></div>
                <div className={styles.DetailHead}>
                  <p>{t("PROFILE")} </p>
                  <h1>{t("PICTURE")}</h1>
                </div>
                <div className={styles.profileSection}>
                  <img
                    src={selectedImage ? selectedImage : ProfileDemo}
                    alt="ProfileDemo"
                  ></img>
                  <div className={styles.profileSectionOption}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                    <CustomButton
                      variant="footerBtnCustom"
                      type="button"
                      onClick={handleButtonClick}
                    >
                      {t(" Select Photo")}
                    </CustomButton>

                    {selectedImage ? (
                      <React.Fragment>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedImage(null);
                            setIsProfileRemove(1);
                            setIsCropper(false);
                            setNoCropImg(null);
                            fileInputRef.current.value = "";
                          }}
                        >
                          {t("REMOVE_PHOTO")}
                        </p>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className={styles.devider}></div>
                <div className={styles.personalBestSection}>
                  <h2>{t("PERSONAL_BEST")}</h2>
                </div>

                <Row lg={1} style={{ marginTop: "2px" }}>
                  {distanceList?.map((item, index) => {
                    const values = inputValues[item.id];
                    return (
                      <Col key={item.id}>
                        <div className={styles.timeGrid} key={index}>
                          <div className={styles.timeHead}>
                            <p>
                              {item.distance}
                              {" KM"}
                            </p>
                          </div>
                          <div className={styles.timeInput}>
                            <div>
                              <input
                                inputMode="numeric"
                                pattern="[0-9]*"
                                type="number"
                                name="hours"
                                value={
                                  addLeadingZero(values?.hours) === "00"
                                    ? ""
                                    : addLeadingZero(values?.hours)
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    "hours",
                                    e.target.value
                                  )
                                }
                              />
                              <p className={styles.personalBestTimeLabel}>
                                {t("HOURS")}
                              </p>
                            </div>

                            <p style={{ marginBottom: "25px" }}>:</p>
                            <div>
                              <input
                                inputMode="numeric"
                                pattern="[0-9]*"
                                type="number"
                                name="minutes"
                                value={
                                  addLeadingZero(values?.minutes) === "00"
                                    ? ""
                                    : addLeadingZero(values?.minutes)
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    "minutes",
                                    e.target.value
                                  )
                                }
                              />
                              <p className={styles.personalBestTimeLabel}>
                                {t("MINUTES")}
                              </p>
                            </div>
                            <p style={{ marginBottom: "25px" }}>:</p>
                            <div>
                              <input
                                inputMode="numeric"
                                pattern="[0-9]*"
                                type="number"
                                name="seconds"
                                value={
                                  addLeadingZero(values?.seconds) === "00"
                                    ? ""
                                    : addLeadingZero(values?.seconds)
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    "seconds",
                                    e.target.value
                                  )
                                }
                              />
                              <p className={styles.personalBestTimeLabel}>
                                {t("SECONDS")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>

                <div className={styles.trainzaStatus}>
                  <div className={styles.statusHeading}>
                    <p>
                      {t("TRAINZA_TEXT")} <span>{t("STATUS_TEXT")}</span>
                    </p>
                  </div>
                  <div className={styles.devider}></div>
                  <div className={styles.switchCase}>
                    <div className={styles.switchCaseInfo}>
                      <h1>{t("ACTIVE_MEMBER")}</h1>
                      <p>{t("TOGGLE_INFO")}</p>
                    </div>

                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      className={styles.switchButton}
                      checked={activeMember}
                      onChange={activeStatus}
                    />
                  </div>
                  <div className={styles.devider}></div>
                  <div className={styles.addBrandText}>
                    <p>
                      {t("ADD_EVENT_BTN")} <span>{t("BRAND_TAB")}</span>
                    </p>
                    <div className={styles.addDevider}></div>

                    <div className={styles.brandInput}>
                      <div className={styles.selectBrand}>
                        <Form.Group className={styles.DetailInput}>
                          <Form.Label className={styles.labelText}>
                            {t("SELECT_BRAND")}
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={selectedBrandId}
                            onChange={(e) => setSelectedBrandId(e.target.value)}
                          >
                            <option value="" defaultValue>
                              {t("SELECT_BRAND")}
                            </option>
                            {brandListData &&
                              brandListData.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item?.clubName}
                                </option>
                              ))}
                          </Form.Select>
                          <img
                            src={SelectImg}
                            alt="SelectImg"
                            className={styles.selectImg}
                          />
                        </Form.Group>
                      </div>
                      <div className={styles.addBtn}>
                        <button
                          type="button"
                          onClick={handleBrandChange}
                          disabled={!selectedBrandId}
                          style={{
                            opacity: !selectedBrandId ? 0.5 : 1,
                            backgroundColor: !selectedBrandId
                              ? "#ff430040"
                              : "",
                            borderColor: !selectedBrandId ? "#ff430040" : "",
                            pointerEvents: !selectedBrandId ? "none" : "auto", // Prevent clicks on the disabled button
                          }}
                        >
                          {t("ADD")}
                        </button>
                      </div>
                    </div>
                  </div>

                  {selectedBrand?.map((brand) => (
                    <div
                      key={brand?.id}
                      className={styles.membershipInfo}
                      ref={(el) => (brandRefs.current[brand.id] = el)}
                    >
                      <div className={styles.subInfo}>
                        <h3>{brand?.clubName || brand?.name}</h3>
                      </div>
                      <div className={styles.validShip}>
                        <Form.Group
                          key={brand.id}
                          className={styles.validInput}
                        >
                          <Form.Label>{t("MEMBERSHIP_VALID_UPTO")}</Form.Label>
                          <div
                            className={styles.dateSelectionBox}
                            onClick={() => {
                              handleShowDateMembership();
                              setDateId(brand.id);
                              setDateToPreview(
                                dates.find(
                                  (dateObj) => dateObj.idOfDate === brand.id
                                )?.date
                                  ? dates.find(
                                      (dateObj) => dateObj.idOfDate === brand.id
                                    ).date
                                  : ""
                              );
                            }}
                          >
                            <img src={CalenderIcon} alt="CalenderIcon"></img>
                            <p>
                              {dates.find(
                                (dateObj) => dateObj.idOfDate === brand.id
                              )?.date
                                ? formatDate(
                                    dates.find(
                                      (dateObj) => dateObj.idOfDate === brand.id
                                    ).date
                                  )
                                : formatDate(
                                    new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() + 1
                                      )
                                    )
                                  )}
                            </p>
                          </div>
                        </Form.Group>
                        <div className={styles.devider}></div>
                        <div className={styles.switchCase}>
                          <div className={styles.switchCaseInfo}>
                            <h1>{t("ACTIVE_MEMBERSHIP")}</h1>
                            <p>{t("MEMBER_ON_OF")}</p>
                          </div>
                          <Form.Check
                            type="switch"
                            id={`custom-switch-active-${brand.id}`}
                            className={styles.switchButton}
                            checked={!!brandMemberActive[brand.id]}
                            onChange={() => {
                              setBrandMmeberActive((prevState) => ({
                                ...prevState,
                                [brand.id]: !prevState[brand.id],
                              }));
                            }}
                          />
                        </div>
                        <div className={styles.devider}></div>
                        <div className={styles.switchCase}>
                          <div className={styles.switchCaseInfo}>
                            <h1>{t("OTHER_CLUB_MEMBERS")}</h1>
                            <p>{t("ALLOW_USERS_INFO")}</p>
                          </div>
                          <Form.Check
                            type="switch"
                            id={`custom-switch-view-${brand.id}`}
                            className={styles.switchButton}
                            checked={!!brandCanViewOther[brand.id]}
                            onChange={() => {
                              setBrandCanViewOther((prevState) => ({
                                ...prevState,
                                [brand.id]: !prevState[brand.id],
                              }));
                            }}
                          />
                        </div>
                      </div>

                      <div className={styles.removeBrand}>
                        <div className={styles.removeBTn}>
                          <CustomButton
                            variant="footerBtnCustom"
                            type="button"
                            onClick={() => {
                              handleShowRemove(brand?.id);
                            }}
                          >
                            <p>
                              {t("REMOVE_BTN")} <span>{t("REMOVE_BRAND")}</span>
                            </p>
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className={styles.cancelOrSave}>
                  <CustomButton
                    variant="cancelBtn"
                    type="button"
                    onClick={() => {
                      setShowtab(1);
                    }}
                  >
                    {t("CANCEL_BUTTON")}
                  </CustomButton>
                  <CustomButton
                    variant="footerBtnCustom"
                    className={styles.saveBtn}
                    type="submit"
                  >
                    {t("SAVE_CHANGES")}
                  </CustomButton>
                </div>
              </Form>
            </>
          ) : detailTabShow === 2 ? (
            <LogBook />
          ) : (
            ""
          )}
        </div>
      </div>

      <CropImg
        handleClose={handleCloseCrop}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setSelectedImage}
        aspectRatio={CONSTANT.ASPECT_RATIO_IN_ADD_MEMBER}
        setIsCropper={setIsCropper}
        setImageSrc={setImageSrc}
      />

      <MemberCalenderComp
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        tileDisabled={tileDisabledForStartDate}
        dateToDiable={new Date()}
        eventData={"allow"}
      />

      <CalenderForMembership
        show={showDateMembership}
        handleClose={handleCloseDateMembership}
        setWeekday={setWeekdayMembership}
        setDay={setDayMembership}
        setMonth={setMonthMembership}
        setYear={setYearMembership}
        selectedDate={dateToPreview}
        eventData={"Allow choose previous"}
        tileDisabled={tileDisabledForStartDateMembership}
      />

      <RemoveBrandModal
        handleClose={handleCloseRemove}
        handleRemoveMember={handleRemoveClick}
        show={removeBrandModal}
      />

      <MembershipModal
        membershipModal={membershipModal}
        handleClose={handleCloseMembership}
        show={membershipModal}
        memberBrandName={memberBrandName}
      />
    </div>
  );
}

export default AddMember;
