import { Col, Form, Row } from "react-bootstrap";
import styles from "./Members.module.css";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-datepicker/dist/react-datepicker.css";
import ProfileDemo from "../../Assests/Images/person.png";
import LogBook from "./LogBook";
import CustomTabButton, {
  CustomButton,
} from "../../ReactBootstrap/CustomTabButton";
import CalenderIcon from "../../Assests/Images/Calender.svg";
import SublyApi from "../../Helpers/Api";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import SelectImg from "../../Assests/Images/SelectImg.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import Calender from "../../Assests/Images/Calender.svg";
import { fetchBrandList, userLogoutClear } from "../../store/slices/userSlice";
import {
  capitalizeFirstLetter,
  convertServerDataInOurArray,
  removeExtraPlus,
  transformTimeArray,
} from "../../Utils/RemovePlus/RemovePlus";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import CropImg from "../../Common/Cropper/CropImg";
import { CONSTANT } from "../../Utils/Constant";
import Loader from "../../Utils/Loader/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import person from "../../Assests/Images/person.png";
import CalenderComp from "../../Common/DatePicker/CalenderComp";
import {
  DAYS_OF_WEEK,
  MONTH_CODE,
  MONTH_NO_CODE,
  MONTH_OF_YEAR,
} from "./MockData";
import {
  fetchImageAsFile,
  formatDate,
  isValidDate,
} from "../../Utils/CommonFunction";
import CalenderForMembership from "../../Common/DatePicker/CalenderForMembership";
import DeleteMember from "./DeleteMember";
import { MASKING } from "../../Common/PhoneMasking";
import RemoveBrandModal from "./RemoveModal";
import MembershipModal from "./MembershipModal";
import MemberCalenderComp from "../../Common/DatePicker/MemberCalenderComp";

function MemberDetailEdit() {
  const { userToken, brandListData } = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("");
  const memberId = location.state.member_id;
  const [detailTabShow, setDetailTabShow] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [phone, setPhone] = useState("");
  const [phoneDialCode, setPhoneDialCode] = useState("27");
  const [phoneCountryCode, setPhoneCountryCode] = useState("Za");
  const fileInputRef = useRef(null);
  const [isCropper, setIsCropper] = useState(false);
  const [isProfileRemove, setIsProfileRemove] = useState(1);
  const [heightList, setHeightList] = useState([]);
  const [weightList, setWeightList] = useState([]);
  const [distanceList, setDistanceList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryset, setCountryset] = useState();
  const [stateset, setStateset] = useState();
  const [heightset, setHeightset] = useState();
  const [weightset, setWeightset] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [noCropImg, setNoCropImg] = useState(null);
  const [isEmailShare, setIsEmailShare] = useState(0);
  const [isPhoneShare, setIsPhoneShare] = useState(0);
  const [brandMemberActive, setBrandMmeberActive] = useState(0);
  const [brandCanViewOther, setBrandCanViewOther] = useState(0);
  const [showCrop, setShowCrop] = useState(false);
  const handleCloseCrop = () => {
    setShowCrop(false);
    fileInputRef.current.value = "";
    setIsCropper(false);
  };
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [removeBrandModal, setRemoveBrandModal] = useState(false);
  const [brandToRemove, setBrandToRemove] = useState(null);
  const handleCloseRemove = () => setRemoveBrandModal(false);
  const [membershipModal, setMembershipModal] = useState(false);
  const handleCloseMembership = () => setMembershipModal(false);

  const [memberBrandName, setMemberBrandName] = useState("");

  const [inputValues, setInputValues] = useState(
    distanceList.reduce((acc, item) => {
      acc[item.id] = {
        hours: 0,
        minutes: 0,
        seconds: 0,
        createdAt: 0,
      };
      return acc;
    }, {})
  );
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [modifyTimeArray, setModifyTimeArray] = useState([]);

  const [modifyTimeArrayWithNoChange, setModifyTimeArrayWithNoChange] =
    useState([]);

  const [isAnyChange, setIsAnyChange] = useState(false);

  const today = new Date().toISOString().split("T")[0];
  const [activeMember, setActiveMember] = useState(true);
  const [defaultContryCountry, setDefaulCountry] = useState("vbmOpe");
  const [selectCountry, setSelectCountry] = useState("");
  const [defaultState, setDefaulState] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);
  const [memberData, setMemeberData] = useState("");

  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();

  const [selectedDate, setSelectedDate] = useState("");

  const [startDate, setStartDate] = useState("");

  const [showDate, setShowDate] = useState(false);

  const currentDate = new Date();

  useEffect(() => {
    setWeekday(DAYS_OF_WEEK[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(MONTH_OF_YEAR[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  useEffect(() => {
    var daySelected = day;

    if (day?.toString()?.length < 2) {
      daySelected = `${0}${day}`;
    } else {
      daySelected = day;
    }
    const rightFormatOfDate = `${year}-${MONTH_CODE[month]}-${daySelected}`;
    if (rightFormatOfDate) {
      setSelectedDate(rightFormatOfDate);
    }
  }, [day, year, month, weekday]);

  const handleShowDate = () => setShowDate(true);

  const handleCloseDate = () => setShowDate(false);

  //=====  function for handle selected brand list scroller====
  const brandRefs = useRef({});
  useEffect(() => {
    if (selectedBrand?.length > 0) {
      const lastBrandId = selectedBrand[selectedBrand.length - 1].id;
      const lastBrandElement = brandRefs.current[lastBrandId];

      if (lastBrandElement) {
        lastBrandElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [selectedBrand]);

  // =====================DATE PICKER ========================

  const [weekdayMembership, setWeekdayMembership] = useState("");
  const [dayMembership, setDayMembership] = useState("");
  const [monthMembership, setMonthMembership] = useState("");
  const [yearMembership, setYearMembership] = useState();
  const [selectedDateMembership, setSelectedDateMembership] = useState("");

  const [dateToPreview, setDateToPreview] = useState();

  const [showDateMembership, setShowDateMembership] = useState(false);

  useEffect(() => {
    if (!showDateMembership) {
      setShowDateMembership("");
    }
  }, [showDateMembership]);

  const [dates, setDates] = useState([]);
  const [dateId, setDateId] = useState("");

  const handleDateSelection = (brandId, selectedDate) => {
    setDates((prevDates) => {
      const existingDateIndex = prevDates?.findIndex(
        (dateObj) => dateObj?.idOfDate === brandId
      );

      if (existingDateIndex !== -1) {
        const updatedDates = [...prevDates];
        updatedDates[existingDateIndex] = {
          date: selectedDate,
          idOfDate: brandId,
        };
        return updatedDates;
      } else {
        return [...prevDates, { date: selectedDate, idOfDate: brandId }];
      }
    });
  };

  //=== function for prevent white space====
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  //====== prevent  white space in email input====
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, ""));
  };

  useEffect(() => {
    if (isValidDate(selectedDateMembership) && dateId && !showDateMembership) {
      handleDateSelection(dateId, selectedDateMembership);
    }
  }, [selectedDateMembership, showDateMembership]);

  const currentDateMembership = new Date();

  useEffect(() => {
    setWeekdayMembership(DAYS_OF_WEEK[currentDateMembership.getDay()]);
    setDay(currentDateMembership.getDate());
    setMonthMembership(MONTH_OF_YEAR[currentDateMembership.getMonth()]);
    setYearMembership(currentDateMembership.getFullYear());
  }, []);

  useEffect(() => {
    var daySelected = day;
    if (dayMembership?.toString()?.length < 2) {
      daySelected = `${0}${dayMembership}`;
    } else {
      daySelected = dayMembership;
    }
    const rightFormatOfDate = `${yearMembership}-${MONTH_CODE[monthMembership]}-${daySelected}`;
    if (rightFormatOfDate) {
      setSelectedDateMembership(rightFormatOfDate);
    }
  }, [dayMembership, yearMembership, monthMembership, weekdayMembership]);

  const handleShowDateMembership = () => setShowDateMembership(true);

  const handleCloseDateMembership = () => setShowDateMembership(false);

  //===========function for form validation using useform===========
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    mode: "onChange",
  });

  //=========== function for handle active status==========
  const activeStatus = () => {
    setActiveMember(!activeMember);
  };

  // Show the modal and store the brand ID to remove
  const handleShowRemove = (id) => {
    setBrandToRemove(id);
    setRemoveBrandModal(true);
  };

  // Remove the brand when confirmed
  const handleRemoveClick = () => {
    if (brandToRemove) {
      setSelectedBrand(
        selectedBrand.filter((brand) => brand.id !== brandToRemove)
      );

      const updatedData = dates.filter(
        (item) => item.idOfDate !== brandToRemove
      );
      setDates(updatedData);

      setBrandToRemove(null); // Reset after removal
      setRemoveBrandModal(false); // Close the modal
    }
  };
  // ======function for calling brand list api============
  async function getBrandList(
    pageNum = 1,
    brandSearch = "",
    onLoadMore = false,
    listForAddMember = 1
  ) {
    await dispatch(
      fetchBrandList({
        userToken: userToken,
        page_no: pageNum,
        search: brandSearch,
        onLoadMore: onLoadMore,
        listForAddMember: listForAddMember,
      })
    ).then((responsejson) => {
      const { response } = responsejson.payload;
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      }
    });
  }

  useEffect(() => {
    getBrandList();
  }, []);

  // -----------function for handle profile image-----------
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNoCropImg(file);
    setIsProfileRemove(0);

    setIsCropper(true);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (isCropper) {
      setShowCrop(true);
    }
  }, [isCropper]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  //====== function for calling hieght weight meta api=======
  const getHeightWidthDistanceMeta = () => {
    const HeightResponse = SublyApi.HeightWidthDistanceMeta("heightMeta");
    HeightResponse.then((res) => {
      setHeightList(res?.data?.heightMeta);
    });

    const WeightResponse = SublyApi.HeightWidthDistanceMeta("weightMeta");
    WeightResponse.then((res) => {
      setWeightList(res?.data?.weightMeta);
    });

    const DistanceResponse = SublyApi.HeightWidthDistanceMeta("distanceMeta");
    DistanceResponse.then((res) => {
      setDistanceList(res?.data?.distanceMeta);
    });
  };

  useEffect(() => {
    getHeightWidthDistanceMeta();
    getCountryMetaData();
  }, []);

  //====== function for calling country state meta api======
  const getCountryMetaData = () => {
    setIsLoading(true);
    const HeightResponse = SublyApi.countryMetaData();
    HeightResponse.then((res) => {
      setCountryList(res?.data?.countryMeta);
      setIsLoading(false);
    });
  };

  const getStateMetaData = (countryId) => {
    setIsLoading(true);
    const StateResponse = SublyApi.stateMetaData(userToken, countryId);
    StateResponse.then((res) => {
      setStateList(res?.data?.stateMeta);
      setIsLoading(false);
    });
  };

  //=========== function for show default country state ============
  useEffect(() => {
    getStateMetaData(defaultContryCountry);
  }, [defaultContryCountry]);

  //=========== function for show default country  ============
  useEffect(() => {
    setValue("COUNTRY", defaultContryCountry);
  }, [countryList]);

  useEffect(() => {
    if (memberData && memberData.status !== undefined) {
      setActiveMember(memberData.status === 1);
    }
  }, [memberData]);

  //======= function for calling member detail api=======
  const handleGetMemberDetail = () => {
    const response = SublyApi.memberDetail(userToken, memberId);
    response.then((response) => {
      setIsLoading(false);
      const clubMemberMapping = response?.data?.clubMemberMapping;
      const clubMemberMappingData = response?.data?.clubMemberMapping;
      const personalBests = response?.data?.personalBests;
      const memberInfo = response?.data?.memberInfo;
      if (response && response.code == STATUS_CODES.SUCCESS) {
        if (response.data && response.data.memberInfo) {
          if (response.data.memberInfo.countryMeta) {
            setDefaulCountry(response.data.memberInfo?.countryMeta);
            setValue("COUNTRY", response.data.memberInfo?.countryMeta);
            setDefaulState(response.data.memberInfo?.stateMeta);
          }
        }
        setMemeberData(response && response.data.memberInfo);
        setSelectedImage(memberInfo?.profilePicture);
        setNoCropImg(memberInfo?.profilePicture);
        setIsEmailShare(memberInfo?.isEmailPubliclyShared);
        setIsPhoneShare(memberInfo?.isNumberPubliclyShared);
        setPhoneDialCode(
          memberInfo?.phoneDialCode ? memberInfo?.phoneDialCode : 27
        );
        setPhoneCountryCode(
          memberInfo?.phoneCountryCode ? memberInfo?.phoneCountryCode : "ZA"
        );
        setPhone(memberInfo?.phoneNumber ? memberInfo?.phoneNumber : "");
        setValue(
          "CONTACT_NO",
          memberInfo?.phoneNumber ? memberInfo?.phoneNumber : ""
        );
        setValue("FIRST_NAME", memberInfo?.firstName);
        setValue("LAST_NAME", memberInfo?.lastName);
        setValue("ADD_EMAIL", memberInfo?.email);

        if (memberInfo?.dateOfBirth) {
          setYear(memberInfo?.dateOfBirth?.substring(0, 4));
          setMonth(MONTH_NO_CODE[memberInfo?.dateOfBirth?.substring(5, 7)]);
          setDay(memberInfo?.dateOfBirth?.substring(8, 10));

          setSelectedDate(memberInfo?.dateOfBirth);
          setStartDate(memberInfo?.dateOfBirth);
        }

        setValue("GENDER", JSON.stringify(memberInfo?.gender));
        setHeightset(memberInfo?.height);
        setWeightset(memberInfo?.weight);
        setCountryset(memberInfo?.countryMeta);
        setStateset(memberInfo?.stateMeta);
        setValue("TOWN_CITY", memberInfo?.town);
        setValue("VALID_UPTO", clubMemberMapping[0]?.membershipValidUntil);
        const convertedData = convertServerDataInOurArray(personalBests);
        const processedData = {};
        convertedData?.forEach((item) => {
          const { id, hours, createdAt, minutes, seconds } = item;
          processedData[id] = {
            hours,
            createdAt,
            minutes: parseInt(minutes),
            seconds: parseInt(seconds),
          };
        });

        setInputValues(processedData);
        setModifyTimeArray(convertedData);
        setModifyTimeArrayWithNoChange(convertedData);
        const membershipActiveState = {};
        const viewOtherMemberState = {};
        clubMemberMapping.forEach((mapping) => {
          membershipActiveState[mapping.clubId] =
            mapping.isMembershipActive !== undefined
              ? JSON.parse(mapping.isMembershipActive)
              : false;
          viewOtherMemberState[mapping.clubId] =
            mapping.canViewOtherMembers !== undefined
              ? mapping.canViewOtherMembers
              : false;
        });

        setBrandMmeberActive(membershipActiveState);
        setBrandCanViewOther(viewOtherMemberState);

        const prefillData = clubMemberMappingData?.map((item) => ({
          id: item.clubId,
          name: item.clubName,
          membershipValidUntil: item.membershipValidUntil,
          activeMembership: item.isMembershipActive,
          canViewOtherMember: item?.canViewOtherMembers,
          createdAt: "",
        }));

        const prefillDates = clubMemberMappingData?.map((item) => ({
          date: item?.membershipValidUntil,
          idOfDate: item?.clubId,
        }));
        setDates(prefillDates);

        setSelectedBrand(prefillData);

        clubMemberMapping.forEach((mapping, index) => {
          setValue(
            `VALID_UPTO_${mapping.clubId}`,
            mapping.membershipValidUntil
          );
        });
      } else if (response?.data?.code === STATUS_CODES.INVALID_TOKEN) {
        Toster(response.data.message, "error");
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      }
    });
  };

  useEffect(() => {
    if (memberId) {
      handleGetMemberDetail();
    }
  }, [memberId]);

  useEffect(() => {
    if (memberId) {
      setTimeout(() => {
        setValue("COUNTRY", countryset);
        setValue("COUNTRY", defaultContryCountry);
        setValue("HEIGHT", heightset);
        setValue("WEIGHT", weightset);
        if (countryset) {
          getStateMetaData(countryset);
        }
        getStateMetaData(defaultContryCountry);
        setTimeout(() => {
          setValue("STATE", stateset);
        }, 1000);
      }, 1000);
    }
  }, [countryset, heightset, weightset, stateset, defaultContryCountry]);

  //====== function for handle date time hours input=====
  const handleInputChange = (id, field, value) => {
    setIsAnyChange(true);
    const inputField = Number(value);
    if (field !== "hours") {
      if (inputField < 60) {
        let stringValue = value.toString();

        let validatedValue = Number(stringValue);

        if (
          (field === "minutes" || field === "seconds") &&
          (validatedValue < 0 || validatedValue > 59)
        ) {
          validatedValue = Math.max(0, Math.min(value, validatedValue));
        }

        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: {
            ...prevValues[id],
            [field]: validatedValue,
            createdAt: new Date().toISOString(),
          },
        }));
      }
    } else if (field == "hours") {
      if (value < 100) {
        let stringValue = value.toString();

        let validatedValue = Number(stringValue);

        if (field === "hours" && (validatedValue < 0 || validatedValue > 99)) {
          validatedValue = Math.max(0, Math.min(value, validatedValue));
        }

        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: {
            ...prevValues[id],
            [field]: validatedValue,
            createdAt: new Date().toISOString(),
          },
        }));
      }
    }
  };

  const addLeadingZero = (digit) => {
    const digitStr = String(digit);

    if (digitStr?.length === 1) {
      return `0${digitStr}`;
    } else {
      return digitStr;
    }
  };

  const generateObjectArray = () => {
    return distanceList.map((item) => ({
      id: item.id,
      ...inputValues[item.id],
    }));
  };

  const modifyArray = () => {
    const objectsArray = generateObjectArray();
    const updatedArray = objectsArray
      .filter((obj) => "hours" in obj || "minutes" in obj || "seconds" in obj)
      .map((obj) => ({
        ...obj,
        hours: obj.hours || "0",
        minutes: obj.minutes || "0",
        seconds: obj.seconds || "0",
      }));

    setModifyTimeArray(updatedArray);
  };

  useEffect(() => {
    modifyArray();
  }, [inputValues]);

  const fetchImageAsBinary = async (url) => {
    setIsLoading(true);
    var path = url;
    const startsWithHtt = path.startsWith("htt");
    if (startsWithHtt) {
      path = `${process.env.REACT_APP_BASE_URL}/proxy?url=${url}`;
    } else {
      path = url;
    }

    try {
      const imageFile = await fetchImageAsFile(`${path}`, "myImage.jpg");
      setIsLoading(false);
      return imageFile;
    } catch (error) {
      console.error("Error fetching image:", error);
      setIsLoading(false);
      throw error;
    }
  };



  const getDateById = (id) => {
    const foundItem = dates?.find((item) => item?.idOfDate === id);
    if (foundItem?.date) {
      // Convert the date to a string in the format YYYY-MM-DD
      const dateOnly = new Date(foundItem.date).toISOString().split("T")[0];
      return dateOnly;
    }
    return null;
  };

  const dobRef = useRef(null);

  //======= function for submit add member form=======

  const OnSubmit = async (data) => {
    setIsLoading(true);
    if (selectedBrand?.length > 0 && selectedBrand?.length !== dates?.length) {
      Toster(`Please select "Membership Valid Until" date`, "error");
      setIsLoading(false);
    }
    if (!phone || phone.length <= 4) {
      setIsLoading(false);
      setError("CONTACT_NO", {
        type: "manual",
        message: "Phone number is required ",
      });

      numberRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }

    const DOB =
      selectedDate &&
      selectedDate !== null &&
      selectedDate !== "undefined-undefined-undefined";
    if (
      !selectedDate ||
      selectedDate == null ||
      selectedDate == "undefined-undefined-undefined"
    ) {
      dobRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      Toster("Please select DOB", "error");
      setIsLoading(false);
    }

    const PostData = new FormData();
    if (memberId) {
      PostData.append("memberId", memberId);
    }
    PostData.append("firstName", data.FIRST_NAME);
    PostData.append("lastName", data.LAST_NAME);
    PostData.append("email", data.ADD_EMAIL);
    PostData.append("isEmailPubliclyShared", isEmailShare ? 1 : 0);
    PostData.append("phoneDialCode", phoneDialCode);
    PostData.append("phoneCountryCode", phoneCountryCode);
    PostData.append("phoneNumber", phone);
    PostData.append("isNumberPubliclyShared", isPhoneShare ? 1 : 0);
    PostData.append("dateOfBirth", selectedDate);
    PostData.append("gender", data.GENDER);
    PostData.append("height", data.HEIGHT ? data.HEIGHT : "");
    PostData.append("weight", data.WEIGHT ? data.WEIGHT : "");
    PostData.append("countryMeta", data.COUNTRY);
    PostData.append("stateMeta", data.STATE);
    PostData.append("town", data.TOWN_CITY);
    const imageFile = selectedImage
      ? await fetchImageAsBinary(selectedImage)
      : "";
    PostData.append("profilePicture", imageFile);
    var PbArray = transformTimeArray(modifyTimeArray);
    PbArray = PbArray.filter((item) => item.time !== "00:00:00");
    PostData.append("personalBests", JSON.stringify(PbArray));
    //===== Create an array to hold the brand objects=======
    const brandsArray = selectedBrand.map((brand) => ({
      id: brand.id,
      membershipValidUntil: getDateById(brand.id),
      activeMembership: brandMemberActive[brand.id] ? "1" : "0",
      canViewOtherMember: brandCanViewOther[brand.id] ? "1" : "0",
      createdAt: "",
    }));

    PostData.append("brands", JSON.stringify(brandsArray));
    PostData.append(
      "removeProfilePicture",
      !selectedImage && !noCropImg ? 1 : 0
    );
    PostData.append("status", activeMember ? "1" : "0");
    if (
      (!phone || phone?.length > 4) &&
      (selectedBrand?.length == dates?.length || selectedBrand?.length < 1)
    ) {
      setIsLoading(true);
      SublyApi.AddMember(userToken, PostData).then((response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];
        if (response?.code === STATUS_CODES.SUCCESS) {
          Toster(t("MEMBER_UPDATE_SUCCESSFULLY"), "success");
          navigate("/members");
          reset();
          setPhone("");
          setPhoneDialCode("27");
          setPhoneCountryCode("za");
          setIsEmailShare(0);
          setIsPhoneShare(0);
          setIsProfileRemove(1);
          setIsCropper(false);
          setBrandCanViewOther(0);
          setSelectedImage(null);
          setImageSrc(null);
          setNoCropImg(null);
          setInputValues(
            distanceList.reduce((acc, item) => {
              acc[item.id] = {
                hours: 0,
                minutes: 0,
                seconds: 0,
                createdAt: 0,
              };
              return acc;
            }, {})
          );
          setModifyTimeArray([]);
        } else if (response?.data?.code == STATUS_CODES.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else if (response?.data?.code == STATUS_CODES.INVALID_BRANDS_OBJECT) {
          Toster(response?.data?.message, "error");
        } else if (response?.data?.code == STATUS_CODES.MEMBER_LIMIT_EXCEEDED) {
          setMemberBrandName(response.data.data);
          setMembershipModal(true);
        } else if (
          response?.data?.code === STATUS_CODES.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODES.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          Toster(t(ResponseCode), "error");
        }
      });
    }
  };

  //====== function for handle brand selection=======

  // const handleBrandChange = () => {
  //   if (selectedBrandId) {
  //     const today = new Date();
  //     const nextYear = new Date(today);
  //     nextYear.setFullYear(today.getFullYear() + 1);

  //     const newBrand = {
  //       id: selectedBrandId,
  //       clubName: brandListData.find((brand) => brand.id === selectedBrandId)
  //         ?.clubName,
  //     };

  //     // Add the new brand and its membership expiration date
  //     setSelectedBrand((prevBrands) => [...prevBrands, newBrand]);

  //     // Set default date for membership (current date + one year)
  //     setDates((prevDates) => [
  //       ...prevDates,
  //       { idOfDate: selectedBrandId, date: nextYear },
  //     ]);

  //     // Clear selected brand
  //     setSelectedBrandId("");
  //   }
  // };

  // new function where we add scroller
  const handleBrandChange = () => {
    if (selectedBrandId) {
      // Check if the brand is already selected
      const isBrandAlreadySelected = selectedBrand.some(
        (brand) => brand.id === selectedBrandId
      );

      if (isBrandAlreadySelected) {
        // Scroll to the already selected brand
        const existingBrandElement = brandRefs.current[selectedBrandId];
        if (existingBrandElement) {
          existingBrandElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      return;
      }

      const today = new Date();
      const nextYear = new Date(today);
      nextYear.setFullYear(today.getFullYear() + 1);

      const newBrand = {
        id: selectedBrandId,
        clubName: brandListData.find((brand) => brand.id === selectedBrandId)
          ?.clubName,
      };

      // Add the new brand and its membership expiration date
      setSelectedBrand((prevBrands) => [...prevBrands, newBrand]);

      // Set default date for membership (current date + one year)
      setDates((prevDates) => [
        ...prevDates,
        { idOfDate: selectedBrandId, date: nextYear },
      ]);
      // Clear selected brand
      setSelectedBrandId("");
    }
  };

  var tileDisabledForStartDateMember = ({ date, view }) => {
    const minDate = new Date().setHours(0, 0, 0, 0);

    return date > minDate;
  };

  // membership calnder
  // var tileDisabledForStartDateMembership = ({ date, view }) => {
  //   // Get the current date
  //   const today = new Date();
  //   // Set minimum selectable date to exactly one year from today
  //   const minSelectableDate = new Date(today);
  //   minSelectableDate.setFullYear(today.getFullYear() + 1);
  //   minSelectableDate.setHours(0, 0, 0, 0);
  //   // Disable dates before the minimum selectable date
  //   return view === "month" && date < new Date().setHours(0, 0, 0, 0);
  //   return date < minSelectableDate;
  // };

  const tileDisabledForStartDateMembership = ({ date, view }) => {
    const today = new Date();

    // Disable dates before today (including today), but allow all future dates
    if (view === "month") {
      const isBeforeToday = date < today;
      return isBeforeToday; // Disable past dates
    }

    return false; // Enable future dates
  };

  //====== function for delete member==========
  const handleDeleteMember = () => {
    const response = SublyApi.DeleteMember(userToken, memberId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (response?.code === STATUS_CODES.SUCCESS) {
        Toster(t("MEMBER_DELETED"), "success");
        navigate("/members");
      } else if (response.data.code == STATUS_CODES.VALIDATION_ERROR) {
        Toster(response.data.message, "error");
      } else if (response?.data?.code === STATUS_CODES.INVALID_TOKEN) {
        Toster(response.data.message, "error");
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  const numberRef = useRef(null);
  useEffect(() => {
    if (
      formState.errors.CONTACT_NO ||
      (phone?.length > 0 && phone?.length < 6)
    ) {
      numberRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [formState.errors.CONTACT_NO]);

  return (
    <div className={styles.memberDetailWrapper}>
      {isLoading && <Loader />}
      <div className={styles.memberSection}>
        <div className={styles.topHead}>
          <p className={styles.heading}>
            {detailTabShow === 1
              ? t("MEMBERS > DETAILS")
              : detailTabShow === 2
              ? t("MEMBERS > LOGBOOK")
              : t("MEMBERS > PASSWORD")}
          </p>
          <div className={styles.devider}></div>

          <div className={styles.option}>
            <div className={styles.buttonSection}>
              <CustomTabButton
                onClick={() => setDetailTabShow(1)}
                variant={detailTabShow === 1 ? "primary" : "secondary"}
              >
                {t("DETAILS_TEXT")}
              </CustomTabButton>

              <CustomTabButton
                onClick={() => setDetailTabShow(2)}
                variant={detailTabShow === 2 ? "primary" : "secondary"}
              >
                {t("LOGBOOK_TEXT")}
              </CustomTabButton>
            </div>
          </div>
          <div className={styles.devider}></div>

          {detailTabShow === 1 ? (
            <>
              <div className={styles.detailBox}>
                <div className={styles.topContent}>
                  <img
                    src={
                      memberData && memberData?.profilePicture
                        ? memberData?.profilePicture
                        : person
                    }
                    alt="personImg"
                  ></img>
                  <p>
                    {capitalizeFirstLetter(memberData?.firstName)}{" "}
                    {capitalizeFirstLetter(
                      memberData?.lastName ? memberData?.lastName : ""
                    )}
                  </p>
                </div>
              </div>

              <div className={styles.bottomContent}>
                <div className={styles.memberDataEdit}>
                  <p>
                    {t("MEMBER_BTN")} <span>{t("DETAILS_TEXT")}</span>
                  </p>
                </div>
              </div>
              <Form onSubmit={handleSubmit(OnSubmit)}>
                <div className={styles.editForm}>
                  <Form.Group className={styles.DetailInput}>
                    <Form.Label className={styles.clublabelText}>
                      {t("FIRST_NAME")}
                    </Form.Label>
                    <Form.Control
                      className={styles.FormInput}
                      type="text"
                      placeholder={`${t("FIRST_NAME_PLACHOLDER")}`}
                      {...register("FIRST_NAME", {
                        onChange: (e) => handleKeyPress(e),
                        ...ValidationSchema.FIRST_NAME,
                      })}
                      isInvalid={!!formState.errors.FIRST_NAME}
                      autoFocus={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.FIRST_NAME?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label className={styles.clublabelText}>
                      {t("SIR_NAME")}
                    </Form.Label>
                    <Form.Control
                      className={styles.FormInput}
                      type="text"
                      placeholder={`${t("SIR_NAME_PLACEHOLDER")}`}
                      {...register("LAST_NAME", {
                        onChange: (e) => handleKeyPress(e),
                        ...ValidationSchema.LAST_NAME,
                      })}
                      isInvalid={!!formState.errors.LAST_NAME}
                      autoFocus={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.LAST_NAME?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label className={styles.clublabelText}>
                      {t("EMAIL_LABEL")}
                    </Form.Label>
                    <Form.Control
                      className={styles.FormInput}
                      type="text"
                      placeholder={t("EMAIL")}
                      {...register("ADD_EMAIL", {
                        onChange: (e) => onInputChange(e),
                        ...ValidationSchema.ADD_EMAIL,
                      })}
                      isInvalid={!!formState.errors.ADD_EMAIL}
                      autoFocus={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.ADD_EMAIL?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className={styles.shareCheck}>
                    <div className="memberCheck">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={isEmailShare}
                          onChange={() => {
                            setIsEmailShare(!isEmailShare);
                          }}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {t("SHARE_PUBLICLY")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <Form.Group className={styles.inputIcon} ref={numberRef}>
                    <Form.Label className={styles.labelText}>
                      {t("CONTACT_NUMBER_LABEL")}
                    </Form.Label>
                    <div className="memberPhoneInputSet">
                      <div className="phoneCountry">
                        <div className="customPhoneDropDown">
                          <p>{phoneCountryCode?.toUpperCase()}</p>
                          <span>{removeExtraPlus(phoneDialCode)}</span>{" "}
                        </div>
                      </div>
                      <PhoneInput
                        {...register("CONTACT_NO", ValidationSchema.CONTACT_NO)}
                        defaultMask={MASKING[phoneDialCode]}
                        value={`${phoneDialCode} ${phone}`}
                        onChange={(value, country) => {
                          if (phoneCountryCode !== country.countryCode) {
                            setPhone("");
                          } else {
                            let dialCode = country.dialCode;
                            let phone = value.slice(
                              dialCode.length,
                              value.length
                            );
                            setPhone(phone);
                            setValue("CONTACT_NO", phone);
                          }

                          setPhoneCountryCode(country.countryCode);
                          setPhoneDialCode(country.dialCode);
                        }}
                        countryCodeEditable={false}
                        copyNumbersOnly={true}
                      />
                      <img
                        src={SelectImg}
                        alt="SelectImg"
                        className={styles.phoneSelectImg}
                      ></img>
                    </div>
                    <div className="validateError">
                      {!phone && t(formState.errors.CONTACT_NO?.message)}
                      {phone?.length > 0 &&
                        phone?.length <= 4 &&
                        t("CONTACT_NO_MUST_BE_ATLEAST_5_DIGIT")}
                    </div>
                  </Form.Group>

                  <div className={styles.shareCheck}>
                    <div className="memberCheck">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          checked={isPhoneShare}
                          id="flexCheckDefault"
                          onChange={() => {
                            setIsPhoneShare(!isPhoneShare);
                          }}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {t("SHARE_PUBLICLY")}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className={styles.datePickerBox} ref={dobRef}>
                    <p>{t("DOB_REQUIRED")}</p>
                    <div
                      className={styles.dateSelection}
                      onClick={handleShowDate}
                    >
                      <img src={Calender} alt="calender"></img>
                      {showDate ? (
                        <p>{t("Select DOB")}</p>
                      ) : (
                        <p>
                          {day ? day : "Select DOB"} {month} {year}
                        </p>
                      )}
                      {/* <p>
                        {day} {month} {year}
                      </p> */}
                    </div>
                  </div>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label>{t("GENDER_REQUIRED")}</Form.Label>
                    <div className={styles.checkBoxArea}>
                      <Form.Check
                        inline
                        label={t("FEMALE")}
                        name="group1"
                        type={"radio"}
                        id={`inline-${"radio"}-2`}
                        value={2}
                        {...register("GENDER", ValidationSchema.GENDER)}
                        isInvalid={!!formState.errors.GENDER}
                      />
                      <Form.Check
                        inline
                        label={t("MALE")}
                        name="group1"
                        type={"radio"}
                        id={`inline-${"radio"}-1`}
                        value={1}
                        {...register("GENDER", ValidationSchema.GENDER)}
                        isInvalid={!!formState.errors.GENDER}
                      />
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.GENDER?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className={styles.weightSelect}>
                    <Form.Group className={styles.DetailInput}>
                      <Form.Label className={styles.labelText}>
                        {t("HEIGHT_LABEL")}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("HEIGHT")}
                        onChange={(e) => {
                          setValue("HEIGHT", e.target.value);
                        }}
                      >
                        <option value="" defaultValue>
                          {t("SELECT_HEIGHT")}
                        </option>
                        {heightList?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.height}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <img
                        src={SelectImg}
                        alt="SelectImg"
                        className={styles.selectImg}
                      ></img>
                    </Form.Group>
                  </div>

                  <div className={styles.weightSelect}>
                    <Form.Group className={styles.DetailInput}>
                      <Form.Label className={styles.labelText}>
                        {t("WEIGHT_LABEL")}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("WEIGHT")}
                        onChange={(e) => {
                          setValue("WEIGHT", e.target.value);
                        }}
                      >
                        <option value="" defaultValue>
                          {t("SELECT_WEIGHT")}
                        </option>
                        {weightList?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.weight}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <img
                        src={SelectImg}
                        alt="SelectImg"
                        className={styles.selectImg}
                      ></img>
                    </Form.Group>
                  </div>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label className={styles.CountrylabelText}>
                      {t("COUNTRY_REQUIRED")}
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      {...register("COUNTRY", ValidationSchema.COUNTRY)}
                      isInvalid={!!formState.errors.COUNTRY}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        {
                        }
                        setValue("COUNTRY", selectedValue);
                        setValue("STATE", "");
                        if (selectedValue) {
                          clearErrors("COUNTRY");
                        }
                        getStateMetaData(selectedValue);
                      }}
                      defaultValue=""
                    >
                      <option value="" defaultValue>
                        {t("SELECT_COUNTRY_PLACEHOLDER")}
                      </option>
                      {countryList?.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <img
                      src={SelectImg}
                      alt="SelectImg"
                      className={styles.selectImgCountry}
                    />
                  </Form.Group>

                  <Form.Group className={styles.DetailInput}>
                    <Form.Label className={styles.labelText}>
                      {t("STATE_PROVINCE_REQUIRED")}
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      {...register("STATE", ValidationSchema.STATE)}
                      isInvalid={!!formState.errors.STATE}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setValue("STATE", selectedValue);

                        // Clear the error when a valid option is selected
                        if (selectedValue) {
                          clearErrors("STATE");
                        }
                      }}
                      disabled={stateList?.length === 0}
                    >
                      <option value="" defaultValue>
                        {t("STATE_PLACEHOLDER_TEXT")}
                      </option>
                      {stateList?.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <img
                      src={SelectImg}
                      alt="SelectImg"
                      className={styles.selectImgCountry}
                    />
                  </Form.Group>
                  <Form.Group className={styles.DetailInput}>
                    <Form.Label>{t("TOWN_CITY_REQUIRED")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("TOWN_PLACEHOLDER_TEXT")}
                      {...register("TOWN_CITY", {
                        onChange: (e) => handleKeyPress(e),
                        ...ValidationSchema.TOWN_CITY,
                      })}
                      isInvalid={!!formState.errors.TOWN_CITY}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.TOWN_CITY?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className={styles.devider}></div>
                <div className={styles.DetailHead}>
                  <p>{t("PROFILE")} </p>
                  <h1>{t("PICTURE")}</h1>
                </div>
                <div className={styles.profileSection}>
                  <img
                    src={selectedImage ? selectedImage : ProfileDemo}
                    alt="ProfileDemo"
                  ></img>
                  <div className={styles.profileSectionOption}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                    <CustomButton
                      variant="footerBtnCustom"
                      type="button"
                      onClick={handleButtonClick}
                    >
                      {t(" Select Photo")}
                    </CustomButton>
                    {selectedImage ? (
                      <React.Fragment>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedImage(null);
                            setIsProfileRemove(1);
                            setIsCropper(false);
                            setNoCropImg(null);
                            fileInputRef.current.value = "";
                            setImageSrc(null);
                          }}
                        >
                          {t("REMOVE_PHOTO")}
                        </p>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <CropImg
                  handleClose={handleCloseCrop}
                  show={showCrop}
                  imageSrc={imageSrc}
                  setSelectedImage={setSelectedImage}
                  aspectRatio={CONSTANT.ASPECT_RATIO_IN_ADD_MEMBER}
                  setIsCropper={setIsCropper}
                  setImageSrc={setImageSrc}
                />
                <div className={styles.devider}></div>
                <div className={styles.personalBestSection}>
                  <h2>{t("PERSONAL_BEST")}</h2>
                </div>

                <Row lg={1} style={{ marginTop: "28px" }}>
                  {distanceList.map((item, index) => {
                    const values = inputValues[item.id];
                    return (
                      <Col key={item.id}>
                        <div className={styles.timeGrid}>
                          <div className={styles.timeHead}>
                            <p>
                              {item.distance}
                              {" KM"}
                            </p>
                          </div>

                          <div className={styles.timeInput}>
                            <div>
                              <input
                                inputMode="numeric"
                                pattern="[0-9]*"
                                type="number"
                                name="hours"
                                value={
                                  addLeadingZero(values?.hours) === "00"
                                    ? ""
                                    : addLeadingZero(values?.hours)
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    "hours",
                                    e.target.value
                                  )
                                }
                              />
                              <p className={styles.personalBestTimeLabel}>
                                {t("HOURS")}
                              </p>
                            </div>

                            <p style={{ marginBottom: "25px" }}>:</p>
                            <div>
                              <input
                                inputMode="numeric"
                                pattern="[0-9]*"
                                type="number"
                                name="minutes"
                                value={
                                  addLeadingZero(values?.minutes) === "00"
                                    ? ""
                                    : addLeadingZero(values?.minutes)
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    "minutes",
                                    e.target.value
                                  )
                                }
                              />
                              <p className={styles.personalBestTimeLabel}>
                                {t("MINUTES")}
                              </p>
                            </div>
                            <p style={{ marginBottom: "25px" }}>:</p>
                            <div>
                              <input
                                inputMode="numeric"
                                pattern="[0-9]*"
                                type="number"
                                name="seconds"
                                value={
                                  addLeadingZero(values?.seconds) === "00"
                                    ? ""
                                    : addLeadingZero(values?.seconds)
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    "seconds",
                                    e.target.value
                                  )
                                }
                              />
                              <p className={styles.personalBestTimeLabel}>
                                {t("SECONDS")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>

                <div className={styles.trainzaStatus}>
                  <div className={styles.statusHeading}>
                    <p>
                      {t("TRAINZA_TEXT")} <span>{t("STATUS_TEXT")}</span>
                    </p>
                  </div>
                  <div className={styles.devider}></div>
                  <div className={styles.switchCase}>
                    <div className={styles.switchCaseInfo}>
                      <h1>{t("ACTIVE_MEMBER")}</h1>
                      <p>{t("TOGGLE_INFO")}</p>
                    </div>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      className={styles.switchButton}
                      checked={activeMember}
                      onChange={activeStatus}
                    />
                  </div>
                  <div className={styles.devider}></div>
                  <div className={styles.addBrandText}>
                    <p>
                      {t("UPDATE_TEXT")} <span>{t("BRAND_TAB")}</span>
                    </p>
                    <div className={styles.addDevider}></div>
                    <div className={styles.brandInput}>
                      <div className={styles.selectBrand}>
                        <Form.Group className={styles.DetailInput}>
                          <Form.Label className={styles.labelText}>
                            {t("SELECT_BRAND")}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            value={selectedBrandId}
                            onChange={(e) => setSelectedBrandId(e.target.value)}
                          >
                            <option value="" defaultValue>
                              {t("SELECT_BRAND")}
                            </option>
                            {brandListData &&
                              brandListData.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item?.clubName}
                                </option>
                              ))}
                          </Form.Select>
                          <img
                            src={SelectImg}
                            alt="SelectImg"
                            className={styles.selectImg}
                          />
                        </Form.Group>
                      </div>
                      <div className={styles.addBtn}>
                        <button
                          type="button"
                          onClick={handleBrandChange}
                          disabled={!selectedBrandId}
                          style={{
                            opacity: !selectedBrandId ? 0.5 : 1,
                            backgroundColor: !selectedBrandId
                              ? "#ff430040"
                              : "",
                            borderColor: !selectedBrandId ? "#ff430040" : "",
                            pointerEvents: !selectedBrandId ? "none" : "auto",
                          }}
                        >
                          {t("ADD")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    {selectedBrand?.map((brand) => (
                      <div
                        key={brand?.id}
                        className={styles.membershipInfo}
                        ref={(el) => (brandRefs.current[brand.id] = el)}
                      >
                        <div className={styles.subInfo}>
                          <h3>{brand?.clubName || brand?.name}</h3>
                        </div>
                        <div className={styles.validShip}>
                          <Form.Group
                            key={brand.id}
                            className={styles.validInput}
                          >
                            <Form.Label>
                              {t("MEMBERSHIP_VALID_UPTO")}
                            </Form.Label>
                            <div
                              className={styles.dateSelectionBox}
                              onClick={() => {
                                handleShowDateMembership();
                                setDateId(brand.id);
                                setDateToPreview(
                                  dates.find(
                                    (dateObj) => dateObj.idOfDate === brand.id
                                  )?.date
                                    ? dates.find(
                                        (dateObj) =>
                                          dateObj.idOfDate === brand.id
                                      ).date
                                    : ""
                                );
                              }}
                            >
                              <img src={CalenderIcon} alt="CalenderIcon"></img>
                              <p>
                                {dates.find(
                                  (dateObj) => dateObj.idOfDate === brand.id
                                )?.date
                                  ? formatDate(
                                      dates.find(
                                        (dateObj) =>
                                          dateObj.idOfDate === brand.id
                                      ).date
                                    )
                                  : formatDate(
                                      new Date(
                                        new Date().setFullYear(
                                          new Date().getFullYear() + 1
                                        )
                                      )
                                    )}
                              </p>
                            </div>
                          </Form.Group>
                          <div className={styles.devider}></div>
                          <div className={styles.switchCase}>
                            <div className={styles.switchCaseInfo}>
                              <h1>{t("ACTIVE_MEMBERSHIP")}</h1>
                              <p>{t("MEMBER_ON_OF")}</p>
                            </div>
                            <Form.Check
                              type="switch"
                              id={`custom-switch-active-${brand.id}`}
                              className={styles.switchButton}
                              checked={!!brandMemberActive[brand.id]}
                              onChange={() => {
                                setBrandMmeberActive((prevState) => ({
                                  ...prevState,
                                  [brand.id]: !prevState[brand.id],
                                }));
                              }}
                            />
                          </div>
                          <div className={styles.devider}></div>
                          <div className={styles.switchCase}>
                            <div className={styles.switchCaseInfo}>
                              <h1>{t("OTHER_CLUB_MEMBERS")}</h1>
                              <p>{t("ALLOW_USERS_INFO")}</p>
                            </div>
                            <Form.Check
                              type="switch"
                              id={`custom-switch-view-${brand.id}`}
                              className={styles.switchButton}
                              checked={!!brandCanViewOther[brand.id]}
                              onChange={() => {
                                setBrandCanViewOther((prevState) => ({
                                  ...prevState,
                                  [brand.id]: !prevState[brand.id],
                                }));
                              }}
                            />
                          </div>
                        </div>

                        <div className={styles.removeBrand}>
                          <div className={styles.removeBTn}>
                            <CustomButton
                              variant="footerBtnCustom"
                              type="button"
                              // onClick={() => handleRemoveClick(brand?.id)}
                              onClick={() => {
                                handleShowRemove(brand?.id);
                              }}
                            >
                              <p>
                                {t("REMOVE_BTN")}{" "}
                                <span>{t("REMOVE_BRAND")}</span>
                              </p>
                            </CustomButton>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={styles.cancelOrSave}>
                  <div style={{ display: "flex" }}>
                    <CustomButton
                      variant="cancelBtn"
                      type="button"
                      onClick={() => navigate("/members")}
                    >
                      {t("CANCEL_BUTTON")}
                    </CustomButton>
                    <button
                      className={styles.cancelBtn}
                      type="button"
                      onClick={() => {
                        handleShowDelete();
                      }}
                    >
                      {t("DELETE_BTN")}
                    </button>
                  </div>
                  <button className={styles.saveBtn} type="submit">
                    {t("SAVE_CHANGES")}
                  </button>
                </div>
              </Form>
            </>
          ) : detailTabShow === 2 ? (
            <LogBook member_id={memberId} memberData={memberData} />
          ) : (
            ""
          )}
        </div>
      </div>

      <MemberCalenderComp
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        tileDisabled={tileDisabledForStartDateMember}
        selectedDate={startDate}
        dateToDiable={new Date()}
        eventData={"allow"}
      />

      <CalenderForMembership
        show={showDateMembership}
        handleClose={handleCloseDateMembership}
        setWeekday={setWeekdayMembership}
        setDay={setDayMembership}
        setMonth={setMonthMembership}
        setYear={setYearMembership}
        selectedDate={dateToPreview}
        dateToDiable={new Date()}
        eventData={"allow"}
        tileDisabled={tileDisabledForStartDateMembership}
      />

      <DeleteMember
        handleClose={handleCloseDelete}
        show={showDelete}
        handleDeleteMember={handleDeleteMember}
      />
      <RemoveBrandModal
        handleClose={handleCloseRemove}
        handleRemoveMember={handleRemoveClick}
        show={removeBrandModal}
      />

      <MembershipModal
        membershipModal={membershipModal}
        handleClose={handleCloseMembership}
        show={membershipModal}
        memberBrandName={memberBrandName}
      />
    </div>
  );
}

export default MemberDetailEdit;
