import styles from "./Members.module.css";
import { useTranslation } from "react-i18next";
import person from "../../Assests/Images/person.png";
import user from "../../Assests/Images/UserImg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import {
  fetchMemberDetail,
  userLogoutClear,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { STATUS_CODES } from "../../Utils/StatusCode";
import {
  capitalizeFirstLetter,
  formatDate,
  formatPhoneNumber,
  removeExtraPlus,
} from "../../Utils/RemovePlus/RemovePlus";
import { CONSTANT } from "../../Utils/Constant";
import Loader from "../../Utils/Loader/Loader";
import { Form } from "react-bootstrap";

function MemberDetail({ setUserName, setProfileImg }) {
  const { userToken } = useSelector((state) => state.user);
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const memberId = location.state.member_id;
  const [detailMemberInfo, setDetailMemberInfo] = useState();
  const [personalBest, setPersonalBest] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [memberMaping, setMemberMaping] = useState([]);



  //===== function for calling brand detail api===========
  const getMemberDetail = () => {
    setIsLoading(true);
    dispatch(
      fetchMemberDetail({ userToken: userToken, memberId: memberId })
    ).then((res) => {
      const response = res.payload;
      if (response && response.code == STATUS_CODES.SUCCESS) {
        setDetailMemberInfo(response.data && response.data.memberInfo);
        setPersonalBest(response?.data?.personalBests);
        setMemberMaping(response?.data?.clubMemberMapping);
        setProfileImg(response?.data?.memberInfo?.profilePicture);
        setUserName(
          `${response?.data?.memberInfo?.firstName} ${
            response?.data?.memberInfo?.lastName
              ? response?.data?.memberInfo?.lastName
              : ""
          }`
        );
        setIsLoading(false);
      }
      if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      }
      setIsLoading(false);
    });
  };

  //========= Fetch brand detail when component mounts or when brandId changes=========
  useEffect(() => {
    getMemberDetail();
  }, []);

  return (
    <div className={styles.memberDetailWrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.memberSection}>
          <div className={styles.borderBox}>
            <div className={styles.detailBox}>
              <div className={styles.topContent}>
                <img
                  src={
                    detailMemberInfo?.profilePicture
                      ? detailMemberInfo?.profilePicture
                      : person
                  }
                  alt="personImg"
                ></img>
                <p>
                  {capitalizeFirstLetter(detailMemberInfo?.firstName)}{" "}
                  {capitalizeFirstLetter(
                    detailMemberInfo?.lastName ? detailMemberInfo?.lastName : ""
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.bottomContents}>
            <div className={styles.memberData}>
              <p>
                {t("MEMBER_BTN")} <span>{t("DETAILS_TEXT")}</span>
              </p>
            </div>

            <div className={styles.memberDetails}>
              <p>{t("FIRST_NAME")}</p>
              <h5>
                {capitalizeFirstLetter(
                  detailMemberInfo?.firstName && detailMemberInfo?.firstName
                )}
              </h5>

              <p>{t("SIR_NAME")}</p>
              <h5>
                {capitalizeFirstLetter(
                  detailMemberInfo?.lastName
                    ? detailMemberInfo?.lastName
                    : "N/A"
                )}
              </h5>

              <p>{t("EMAIL_PUBLIC")}</p>
              <h5>
                {capitalizeFirstLetter(
                  detailMemberInfo?.email ? detailMemberInfo?.email : "N/A"
                )}
              </h5>

              <p>{t("CONTACT_NUMBER_LABEL")}</p>
              <h5>
                {" "}
                <h5>
                  {detailMemberInfo?.phoneNumber
                    ? `${removeExtraPlus(
                        detailMemberInfo?.phoneDialCode
                      )} - ${formatPhoneNumber(detailMemberInfo?.phoneNumber)}`
                    : t("N/A")}
                </h5>
              </h5>

              <p>{t("DOB_REQUIRED")}</p>
              <h5>
                {formatDate(
                  detailMemberInfo?.dateOfBirth
                    ? detailMemberInfo?.dateOfBirth
                    : "N/A"
                )}
              </h5>

              <p>{t("GENDER_REQUIRED")}</p>
              <h5>
                {detailMemberInfo?.gender === 1
                  ? "Male"
                  : detailMemberInfo?.gender === 2
                  ? "Female"
                  : "N/A"}
              </h5>

              <p>{t("HEIGHT_LABEL")}</p>
              <h5>{detailMemberInfo?.heightDescription ?? "N/A"}</h5>

              <p>{t("WEIGHT_LABEL")}</p>
              <h5>{detailMemberInfo?.weightDescription ?? "N/A"}</h5>

              <p>{t("COUNTRY_TEXT")}</p>
              <h5>
                {detailMemberInfo?.country ? detailMemberInfo?.country : "N/A"}
              </h5>

              <p>{t("STATE_TEXT")}</p>
              <h5>
                {detailMemberInfo?.state ? detailMemberInfo?.state : "N/A"}
              </h5>

              <p>{t("TOWN_CITY")}</p>
              <h5>{detailMemberInfo?.town ? detailMemberInfo?.town : "N/A"}</h5>
            </div>
          </div>

          <div className={styles.profilePic}>
            <p>
              {t("PROFILE_TEXT")}
              <span> {t("PICTURE_TEXT")} </span>
            </p>
            <img
              src={
                detailMemberInfo?.profilePicture
                  ? detailMemberInfo?.profilePicture
                  : user
              }
              alt="UserImg"
            ></img>
          </div>
          <div className={styles.devider}></div>

          <div className={styles.personalBest}>
            <div className={styles.detailSubHeading}>
              <p>
                {t("PERSONAL_TEXT")} <span>{t("BESTS_TEXT")}</span>
              </p>
            </div>

            {personalBest && personalBest.length > 0 ? (
              personalBest.map((item, index) => {
                return (
                  <div className={styles.personalBestBox} key={index}>
                    <p>
                      {item?.distance}KM <span>{item?.bestTime}</span>
                    </p>
                  </div>
                );
              })
            ) : (
              <div className={styles.NaBox}>
                <p>N/A</p>
              </div>
            )}
          </div>

          <div className={styles.devider}></div>

          <div className={styles.detailSubHeading}>
            <p>
              {t("MEMBERSHIP")} <span>{t("INFO")}</span>
            </p>
          </div>

          <div className={styles.clubNameCheck}>
            {memberMaping.length === 0 ? (
              <div className={styles.NaBox}>
                <p>N/A</p>
              </div>
            ) : (
              memberMaping.map((club) => (
                <div key={club.clubId}>
                  <Form.Check
                    className="selectCheckBoxClubName"
                    type="checkbox"
                    checked={true}
                    label={club.clubName}
                  />
                </div>
              ))
            )}
          </div>

          {/* <div className={styles.detailInfo}>
            <p>{t("MEMBERSHIP_VALID_UNTIL")}</p>
            <h1>
              {memberMaping[0]?.membershipValidUntil
                ? formatDate(memberMaping[0]?.membershipValidUntil)
                : "NA"}
            </h1>
          </div>

          <div className={styles.detailInfo}>
            <h1>{t("ACTIVE_MEMBERSHIP")}</h1>
            <h1>
              {memberMaping[0]?.isMembershipActive === CONSTANT.ACTIVE
                ? "Active"
                : "Inactive"}{" "}
            </h1>
          </div>

          <div className={styles.detailInfo}>
            <h1>{t("CAN_VIEW_OTHER_CLUB_MEMBER")}</h1>
            <h1>
              {" "}
              {memberMaping[0]?.canViewOtherMembers === CONSTANT.ACTIVE
                ? "Active"
                : "Inactive"}{" "}
            </h1>
          </div> */}

          <div className={styles.devider}></div>
          <div className={styles.editBtn}>
            <div
              className={styles.editDetail}
              onClick={() =>
                navigate("/members/member-details-edit", {
                  state: { member_id: detailMemberInfo.id },
                })
              }
            >
              <CustomButton variant="footerBtnCustom" type="button">
                <p>
                  <span>{t("EDIT_BUTTON")}</span>
                </p>
              </CustomButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MemberDetail;
